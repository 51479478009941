// Generated by Framer (f1edf09)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, getPropertyControls, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/534oB9Tn5cZB6VZbkWLJ/Video.js";
const VideoFonts = getFonts(Video);
const VideoControls = getPropertyControls(Video);

const enabledGestures = {iikl2kg49: {hover: true}};

const cycleOrder = ["iikl2kg49"];

const variantClassNames = {iikl2kg49: "framer-v-org3s9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; httpsVimeoCom766555009?: string }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "iikl2kg49", httpsVimeoCom766555009: YempJ5j1P = "URL", ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "iikl2kg49", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-mUFfA", classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-org3s9", className)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"iikl2kg49"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"iikl2kg49-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><motion.div className={"framer-19h5a8d"} data-framer-name={"Overlay"} layoutDependency={layoutDependency} layoutId={"q33aOeYkC"} style={{backgroundColor: "rgba(0, 0, 0, 0.2)"}} transition={transition} variants={{"iikl2kg49-hover": {backgroundColor: "rgba(0, 0, 0, 0)"}}}/><motion.div className={"framer-uwqvbz-container"} layoutDependency={layoutDependency} layoutId={"ZHiO1n2Ce-container"} transition={transition}><Video backgroundColor={"rgba(0, 0, 0, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} canvasPlay={false} controls={false} height={"100%"} id={"ZHiO1n2Ce"} isMixedBorderRadius={false} layoutId={"ZHiO1n2Ce"} loop muted objectFit={"cover"} playing={false} posterEnabled={false} srcFile={new URL("assets/XdYshf2TB1HKSCAuQso8bTRpKm0.mp4", import.meta.url).href} srcType={"URL"} srcUrl={"https://vimeo.com/766555009"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"} {...addPropertyOverrides({"iikl2kg49-hover": {playing: true, srcType: YempJ5j1P}}, baseVariant, gestureVariant)}/></motion.div></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-mUFfA [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mUFfA * { box-sizing: border-box; }", ".framer-mUFfA .framer-1pefuzk { display: block; }", ".framer-mUFfA .framer-org3s9 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: 500px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 500px; }", ".framer-mUFfA .framer-19h5a8d { align-content: center; align-items: center; bottom: 0px; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 10px; justify-content: center; left: 0px; overflow: visible; padding: 0px 0px 0px 0px; position: absolute; right: 0px; top: 0px; z-index: 1; }", ".framer-mUFfA .framer-uwqvbz-container { flex: none; height: 500px; position: relative; width: 500px; }", ".framer-mUFfA .framer-v-org3s9 .framer-org3s9 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mUFfA .framer-org3s9, .framer-mUFfA .framer-19h5a8d { gap: 0px; } .framer-mUFfA .framer-org3s9 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-mUFfA .framer-org3s9 > :first-child { margin-left: 0px; } .framer-mUFfA .framer-org3s9 > :last-child { margin-right: 0px; } .framer-mUFfA .framer-19h5a8d > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-mUFfA .framer-19h5a8d > :first-child { margin-top: 0px; } .framer-mUFfA .framer-19h5a8d > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 500
 * @framerIntrinsicWidth 500
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"R7QhKOA_U":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"YempJ5j1P":"httpsVimeoCom766555009"}
 */
const FramerOMVTK_ZNh: React.ComponentType<Props> = withCSS(Component, css, "framer-mUFfA") as typeof Component;
export default FramerOMVTK_ZNh;

FramerOMVTK_ZNh.displayName = "Play on Hover Copy";

FramerOMVTK_ZNh.defaultProps = {height: 500, width: 500};

addPropertyControls(FramerOMVTK_ZNh, {YempJ5j1P: VideoControls?.["srcType"] && {...VideoControls["srcType"], defaultValue: "URL", hidden: undefined, title: "Https Vimeo Com 766555009"}} as any)

addFonts(FramerOMVTK_ZNh, [...VideoFonts])